<template>
  <div>
    <JobProfileCard ref="job-profile-card" />
    <BaseDocumentationCard
      ref="base-documentation-card"
      v-model="documentation"
      :resource="computedJob"
      :observations="observations"
      :delete-end-point="deleteEndPoint"
      :update-end-point="updateEndPoint"
      :readonly="readonly"
    />
    <JobConditionsCard ref="job-conditions-card" />
    <JobTabs
      :job-id="$route.params.id.toString()"
      :job="job"
      @tab-activated="handleTabActivated"
      @updateJob="fetchData"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import JobProfileCard from '@/components/jobs/card/JobProfileCard.vue'
import BaseDocumentationCard from '@/components/ui/card/BaseDocumentationCard.vue'
import JobConditionsCard from '@/components/jobs/card/JobConditionsCard.vue'
import JobTabs from '@/components/jobs/tab/JobTabs.vue'

export default {
  components: {
    JobTabs, JobConditionsCard, BaseDocumentationCard, JobProfileCard,
  },
  computed: {
    ...mapGetters('job', {
      job: 'getJob',
      readonly: 'getIsReadOnlyMode',
    }),
    observations() {
      return this.job?.observations || 'Sin observaciones'
    },
    computedJob() {
      if (!this.job) {
        return {}
      }

      const computedJob = { ...this.job }
      delete computedJob.contract

      return computedJob
    },
    documentation: {
      get() {
        return this.job?.documentation || []
      },
      set(value) {
        this.setJobDocumentation(value)
      },
    },
    deleteEndPoint() {
      return this.job ? `/jobs/delete_doc/${this.job.id}` : null
    },
    updateEndPoint() {
      return this.job ? `/jobs/${this.job.id}` : null
    },
  },
  mounted() {
    this.fetchData()
    this.$store.commit('certifications/setFilters', {})
    this.$store.commit('maintenanceCertifications/setFilters', {})
  },
  methods: {
    ...mapActions('job', ['loadJob', 'setJobDocumentation']),
    ...mapActions('app', ['setLoading']),
    async fetchData() {
      try {
        this.setLoading(true)
        await this.loadJob(this.$route.params.id)
      } finally {
        this.setLoading(false)
      }
    },
    handleTabActivated() {
      this.$refs['base-documentation-card'].setCollapse(false)
      this.$refs['job-conditions-card'].setCollapse(false)
     // this.$refs['job-profile-card'].setCollapse(false)
    },
  },
}
</script>

<style scoped>
</style>
